import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import logo from "./images/logo.png";
import fecha from "./images/fecha.png";
import Timer from "./components/Timer";
import location from "./images/location.png";
import divisor2 from "./images/divisor_3.png";
import FormfacadeEmbed from "@formfacade/embed-react";
import nago from "./images/nago.JPG";

const Home = ({ double }) => {
  useEffect(() => {
    const sections = document.querySelectorAll(".section");
    sections.forEach((section) => {
      section.classList.add("visible");
    });
  }, []);

  return (
    <div className="app">
      <div className="section section1">
        <img src={logo} alt="Logo" className="centered-logo" />
      </div>

      <div className="section section1">
        <img src={fecha} alt="fecha" className="fecha" />
        <Timer targetDate="2024-10-19T19:00:00" />
      </div>

      <div className="section section1">
        <img src={divisor2} alt="divisor" className="divisor" />
      </div>

      <div className="section">
        <h1>Ubicación</h1>
        <p>Jano's San Telmo Boutique</p>
        <p>México 334, CABA</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <p style={{ margin: 0 }}>Como llegar:</p>
          </div>
          <div>
            <a
              href="https://maps.app.goo.gl/MHF2RVpZA61DxAci6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img height="40px" width="40px" src={location} alt="location" />
            </a>
          </div>
        </div>
      </div>

      <div className="section section1">
        <img src={divisor2} alt="divisor" className="divisor" />
      </div>

      <div className="section">
        <h1>Dress Code</h1>
        <p>Elegante</p>
        <p>No te atrevas a ponerte algo blanco ni algo rosa</p>
      </div>

      <div className="section section1">
        <img src={divisor2} alt="divisor" className="divisor" />
      </div>

      <div className="section">
        <h1>Sugerencia de regalo</h1>
        <p>Tu presencia es lo más importante para nosotros.</p>
        <p>Si querés hacernos un presente, te dejamos nuestro CBU (USD y $)</p>
        <p>Alias: nago.boda</p>
        <p>CBU: 0720175888000037166786</p>
      </div>

      <div className="section section1">
        <img src={divisor2} alt="divisor" className="divisor" />
      </div>

      <div className="section">
        <h1>Confirmación de asistencia</h1>
        <p>
          Esta invitación es para: <br />{" "}
          {double ? "Dos Personas" : "Una Persona"}
        </p>
        {double && (
          <p>Si tu invitación es para más de uno, colocar el nombre de ambos</p>
        )}
      </div>
      <FormfacadeEmbed
        formFacadeURL="https://formfacade.com/include/114075918647063495976/form/1FAIpQLScB9g25zT9sg3QqbLCCa63TsqmBxJ6rr0hU4Gm3X9ETo9c5Mg/classic.js/?div=ff-compose"
        onSubmitForm={() => console.log("Form submitted")}
      />

      <div className="section section1">
        <h1></h1>
        <p>Los esperamos para festejar el amor con nosotros</p>
      </div>

      <div className="section section1">
        <img src={nago} alt="Logo" className="centered-logo-bottom" />
      </div>

      <div className="section section1">
        <h1 className="h1-bottom">Natu y Gonza</h1>
      </div>

      <div className="section section1">
        <img src={divisor2} alt="divisor" className="divisor" />
      </div>
    </div>
  );
};

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home double={false} />} />
      <Route path="/double" element={<Home double={true} />} />
    </Routes>
  </Router>
);

export default App;
